import { graphql, Link, PageProps } from "gatsby"
import * as React from "react"
import { css } from "@emotion/react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../templates/layout"
import Seo from "../templates/seo"
import BlogPostList from "../components/organisms/blog-post-list"
import Sidebar from "../components/organisms/sidebar"
import Card from "../components/atoms/card"
import {
  l_page_container,
  l_page_content,
} from "../components/atoms/__layout"

const BlogIndex: React.FC<PageProps<Queries.BlogIndexQuery>> = ({ data }) => {
  const postdata = data?.allMarkdownRemark?.nodes
  const name = data?.site?.siteMetadata?.author?.name
  const description = data?.site?.siteMetadata?.description
  const breakpoints = useBreakpoint()
  return (
    <Layout>
      <Seo title="all page" />
      <div css={l_page_container}>
        <section css={l_page_content}>
          <Card ><p css={link_title}>最近の投稿</p></Card>
          <BlogPostList postsdata={postdata} />
          <Card>
            <Link css={link_title} to='/allpage'>もっと投稿をみる</Link>
          </Card>
        </section>
        {breakpoints.pc ? <Sidebar toc={false} author={name} description={description} /> : <div />}
      </div>
    </Layout >
  )
}

export default BlogIndex


export const pageQuery = graphql`
  query BlogIndex {
    site {
      siteMetadata {
      title
      description
      author {
        name
      }
    }
  }
  allMarkdownRemark(
  sort: {fields: [frontmatter___date], order: DESC }
  filter: {fields: {slug: {regex: "//blog/" } } }
  limit: 7
  ) {
    nodes {
    excerpt
        fields {
    slug
  }
  frontmatter {
    date(formatString: "YYYY.MM.DD")
  title
  description
  tags
        }
      }
    }
  }
  `
const link_title = css`
  color: black;
  text-decoration: none;
  font-size: 28px;
  padding: 10px;
  justify-content: center;
  display: flex;
  font-weight: 700;
  margin: 0;
  `